import React, { useMemo, useRef, useState } from 'react'

import {
  AutenticacaoContainer,
  Botao,
  FormRef,
  FormUnform,
  FuncoesDataHora,
  IconeCalendarioPequeno,
  IconeComentario,
  IconeDoAvatarDoUsuario,
  IconeLixeira,
  IconeRelogioPequeno,
  IconeResponder,
  IconeRespostaComentario,
  ComentarioTopicoArquivoInput,
  IconeBaixar,
  Visao,
  Tooltip,
  IconeCadeado,
  Informacao
} from 'src/componentes'
import { CHAVE_AVATAR_ALUNO } from 'src/componentes/barra-superior-layout/providers/barra-superior.provider'
import { EditorUnform } from 'src/componentes/unform/editor'
import { sanitizeUtil } from 'src/componentes/utils'
import { InfoPrivado } from 'src/paginas/sala-aula/outros-cursos/grupo-discussao/topico/styles'
import { Api } from 'src/servicos'
import * as Storage from 'src/servicos/local-storage'
import { Comentario as ComentarioType, TipoGrupoDiscussao } from 'src/tipos'

import { ContainerBotao, ContainerPrivado, ImagemUsuario } from '../styles'
import { schema } from './schema'
import {
  Container,
  Conteudo,
  ContainarStatus,
  TextoComentario,
  ContainerChildren,
  ContainerResponder,
  TextoResponder,
  Linha,
  ContainerDownloadArquivo,
  ContainerBotoesDeletarComentario,
  TituloComentario
} from './styles'
import { ComentarioProps } from './tipos'

const avatar = Storage.obter(CHAVE_AVATAR_ALUNO) as { foto: string }

export const Comentario: React.FC<ComentarioProps> = ({
  comentarios,
  grupo,
  topico,
  tipoGrupoDiscussao,
  carregarTopico
}) => {
  const [respondendo, definirRespondendo] = useState<string>(null)
  const [carregando, definirCarregando] = useState(false)
  const [
    confirmacaoDeletarComentario,
    definirConfirmacaoDeletarComentario
  ] = useState(false)
  const [comentarioExclusaoId, definirComentarioExclusaoId] = useState('')
  const formRef = useRef<FormRef>()
  const { usuario, visao } = AutenticacaoContainer.useContainer()
  const tipoTCC = tipoGrupoDiscussao === TipoGrupoDiscussao.TCC
  const visaoResposta = useMemo(
    () =>
      visao === Visao.IPGS ||
      visao === Visao.Professor ||
      visao === Visao.Parceiro,
    [visao]
  )
  const dica = {
    dicaId: '1',
    dicaInfo:
      'Suas resposta são privadas, somente você e o professor conseguem visualizar.'
  }

  const dica2 = {
    dicaId: '1',
    dicaInfo:
      'Suas resposta são privadas, somente você e o aluno conseguem visualizar.'
  }

  const aoClicarResponder = (comentario: string) => {
    formRef?.current?.clearField('comentario')
    definirRespondendo(comentario)
  }

  const adicionarResposta = async (dados: {
    comentario: string
    privado: boolean
    arquivo?: File | string
  }) => {
    try {
      definirCarregando(true)

      await Api.CriarRespostaComentario(
        grupo,
        topico,
        respondendo,
        sanitizeUtil(dados.comentario),
        dados.privado ?? false,
        dados.arquivo
      )

      formRef.current?.clearField('comentario')
      formRef.current?.clearField('arquivo')
      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  const visaoIpgs = useMemo(() => {
    return visao === Visao.IPGS
  }, [visao])

  const deletarComentario = async (
    comentarioId: string,
    tipo: 'comentario' | 'resposta',
    respostaComentarioId?: string
  ) => {
    try {
      definirCarregando(true)

      if (tipo === 'comentario') {
        await Api.DeletarComentario(grupo, topico, comentarioId)
      }

      if (tipo === 'resposta') {
        await Api.DeletarRespostaComentario(
          grupo,
          topico,
          comentarioId,
          respostaComentarioId
        )
      }

      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  const renderizarResponder = (comentario: ComentarioType) => {
    return respondendo === comentario?.comentarioId ? (
      <ContainerResponder>
        <FormUnform
          ref={formRef}
          acaoSucesso={adicionarResposta}
          schema={schema}
        >
          <Conteudo>
            <Linha>
              <ImagemUsuario>
                {avatar && avatar.foto ? (
                  <img src={avatar.foto} />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
              </ImagemUsuario>
              <EditorUnform
                id="comentario"
                name="comentario"
                label="Adicionar um comentário"
              />
            </Linha>
          </Conteudo>
          <ContainerBotao>
            <ComentarioTopicoArquivoInput id="arquivo" name="arquivo" />
            {!tipoTCC && (
              <ContainerPrivado>
                {visaoResposta ? (
                  <InfoPrivado
                    data-tip={dica2.dicaInfo}
                    data-for={dica2.dicaId}
                  >
                    <Informacao />
                    <Tooltip id={dica2.dicaId} place={'right'} html={true} />
                  </InfoPrivado>
                ) : (
                  <InfoPrivado data-tip={dica.dicaInfo} data-for={dica.dicaId}>
                    <Informacao />
                    <Tooltip id={dica.dicaId} place={'right'} html={true} />
                  </InfoPrivado>
                )}
              </ContainerPrivado>
            )}
            <Botao
              texto={tipoTCC ? 'Enviar Mensagem' : 'Enviar Comentário'}
              carregando={carregando}
              type="button"
              onClick={() => formRef?.current?.submitForm()}
            />
          </ContainerBotao>
        </FormUnform>
      </ContainerResponder>
    ) : (
      <ContainerResponder
        onClick={() => aoClicarResponder(comentario.comentarioId)}
      >
        {IconeResponder}
        <TextoResponder> Responder </TextoResponder>
      </ContainerResponder>
    )
  }

  const renderizarComentario = (
    tipo: 'comentario' | 'resposta',
    comentario: ComentarioType,
    comentarioOrigemId?: string,
    existeComentarioProfessor?: boolean,
    children?: JSX.Element
  ) => {
    return (
      <div
        key={comentario.comentarioId}
        className={
          (comentario.respostasComentario?.length < 1 ||
            !existeComentarioProfessor) &&
          'comentario-nao-respondido'
        }
      >
        {tipo === 'comentario' ? IconeComentario : IconeRespostaComentario}
        <Conteudo>
          <TituloComentario>
            {comentario?.nomeUsuario}{' '}
            {comentario?.privado ? (
              <>
                <div
                  data-tip="Somente o criador do tópico/comentário e você poderão ver esse comentário"
                  data-for={`${comentario?.comentarioId}-tooltip-cadeado-comentario`}
                >
                  {IconeCadeado}
                </div>
                <Tooltip
                  id={`${comentario?.comentarioId}-tooltip-cadeado-comentario`}
                  place="right"
                  offset={{ top: 5 }}
                />
              </>
            ) : (
              <></>
            )}
          </TituloComentario>
          <ContainarStatus>
            <span>{tipo === 'comentario' ? 'Postado' : 'Respondido'} em</span>
            {IconeCalendarioPequeno}
            <span>{FuncoesDataHora.novaData(comentario?.dataInclusao)}</span>
            {IconeRelogioPequeno}
            <span>
              {FuncoesDataHora.horaFormatada24H(
                new Date(comentario?.dataInclusao)
              )}
            </span>
            <span>
              <button
                type="button"
                data-tip="Excluir Comentário"
                data-for={`${comentario.comentarioId}-tooltip-excluir-comentario`}
                onClick={() => {
                  definirConfirmacaoDeletarComentario(true)
                  definirComentarioExclusaoId(comentario.comentarioId)
                }}
                disabled={!visaoIpgs}
              >
                {IconeLixeira}
              </button>
              <Tooltip
                id={`${comentario.comentarioId}-tooltip-excluir-comentario`}
                place="right"
                offset={{ bottom: 10 }}
              />
            </span>
            {confirmacaoDeletarComentario &&
              comentarioExclusaoId === comentario.comentarioId && (
                <span>
                  <ContainerBotoesDeletarComentario>
                    <button
                      type="button"
                      data-tip="Excluir Comentário"
                      data-for={`${comentario.comentarioId}-tooltip-excluir-comentario`}
                      onClick={() => {
                        definirConfirmacaoDeletarComentario(false)
                      }}
                      disabled={!visaoIpgs}
                    >
                      Cancelar
                    </button>
                    <button
                      type="button"
                      data-tip="Excluir Comentário"
                      data-for={`${comentario.comentarioId}-tooltip-excluir-comentario`}
                      onClick={() => {
                        const idComentario =
                          tipo === 'comentario'
                            ? comentario.comentarioId
                            : comentarioOrigemId
                        deletarComentario(
                          idComentario,
                          tipo,
                          comentario.comentarioId
                        )
                      }}
                      disabled={!visaoIpgs}
                    >
                      Confirmar
                    </button>
                  </ContainerBotoesDeletarComentario>
                </span>
                // eslint-disable-next-line
              )}
          </ContainarStatus>
          <TextoComentario>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeUtil(
                  comentario?.texto?.replaceAll('<p></p>', '<br />')
                )
              }}
            />
          </TextoComentario>
          {comentario.possuiArquivo && (
            <ContainerDownloadArquivo>
              {' '}
              <button
                type="button"
                data-tip="Baixar"
                data-for={`${comentario.comentarioId}-tooltip-editar`}
                onClick={async e => {
                  e.stopPropagation()
                  if (tipo === 'comentario') {
                    await Api.DownloadArquivoComentario(
                      topico,
                      comentario.comentarioId
                    )
                  } else {
                    await Api.DownloadArquivoComentarioResposta(
                      topico,
                      comentarioOrigemId,
                      comentario.comentarioId
                    )
                  }
                }}
              >
                {IconeBaixar}
              </button>
            </ContainerDownloadArquivo>
          )}
          {children}
          {tipo === 'comentario' &&
            (comentario.usuarioId === usuario.id || visaoResposta) &&
            renderizarResponder(comentario)}
        </Conteudo>
      </div>
    )
  }

  return (
    <>
      <Container>
        {comentarios.map(comentario => {
          const existeComentarioProfessor =
            comentario?.respostasComentario.filter(
              r => r.usuarioId !== comentario.usuarioId
            ).length >= 1

          return renderizarComentario(
            'comentario',
            comentario,
            null,
            existeComentarioProfessor,
            <ContainerChildren>
              {comentario.respostasComentario.map(resposta =>
                renderizarComentario(
                  'resposta',
                  resposta,
                  comentario.comentarioId,
                  existeComentarioProfessor
                )
              )}
            </ContainerChildren>
          )
        })}
      </Container>
    </>
  )
}

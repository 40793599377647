import React, { useMemo, useRef, useState } from 'react'

import {
  AutenticacaoContainer,
  Botao,
  Informacao,
  FormRef,
  FormUnform,
  FuncoesDataHora,
  IconeCadeado,
  IconeCalendarioPequeno,
  IconeComentario,
  IconeDoAvatarDoUsuario,
  IconeRelogioPequeno,
  IconeResponder,
  IconeRespostaComentario,
  Tooltip,
  Visao
} from 'src/componentes'
import { CHAVE_AVATAR_ALUNO } from 'src/componentes/barra-superior-layout/providers/barra-superior.provider'
import { EditorUnform } from 'src/componentes/unform/editor'
import { sanitizeUtil } from 'src/componentes/utils'
import { InfoPrivado } from 'src/paginas/sala-aula/outros-cursos/grupo-discussao/topico/styles'
import { Api } from 'src/servicos'
import * as Storage from 'src/servicos/local-storage'
import { Comentario as ComentarioType } from 'src/tipos'

import { ContainerBotao } from '../styles'
import { schema } from './schema'
import {
  Container,
  Conteudo,
  ContainarStatus,
  TextoComentario,
  ContainerChildren,
  TituloComentario,
  TextoResponder,
  ContainerResponder,
  Linha,
  ImagemUsuario
} from './styles'
import { ComentarioProps } from './tipos'
const avatar = Storage.obter(CHAVE_AVATAR_ALUNO) as { foto: string }

export const Comentario: React.FC<ComentarioProps> = ({
  comentarios,
  topico,
  grupo,
  carregarTopico
}) => {
  const [respondendo, definirRespondendo] = useState<string>(null)
  const [carregando, definirCarregando] = useState(false)
  const formRef = useRef<FormRef>()
  const { usuario, visao } = AutenticacaoContainer.useContainer()
  const dica = {
    dicaId: '1',
    dicaInfo:
      'Suas resposta são privadas, somente você e o professor conseguem visualizar.'
  }

  const dica2 = {
    dicaId: '1',
    dicaInfo:
      'Suas resposta são privadas, somente você e o aluno conseguem visualizar.'
  }

  const visaoResposta = useMemo(
    () =>
      visao === Visao.IPGS ||
      visao === Visao.Professor ||
      visao === Visao.Parceiro,
    [visao]
  )

  const aoClicarResponder = (comentario: string) => {
    formRef?.current?.clearField('comentario')
    definirRespondendo(comentario)
  }

  const adicionarResposta = async (dados: {
    comentario: string
    privado: boolean
    arquivo?: File | string
  }) => {
    try {
      definirCarregando(true)

      await Api.CriarRespostaComentario(
        grupo,
        topico,
        respondendo,
        sanitizeUtil(dados.comentario),
        true,
        dados.arquivo
      )

      formRef.current?.clearField('comentario')
      formRef.current?.clearField('arquivo')
      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  const renderizarResponder = (comentario: ComentarioType) => {
    return respondendo === comentario?.comentarioId ? (
      <ContainerResponder>
        <FormUnform
          ref={formRef}
          acaoSucesso={adicionarResposta}
          schema={schema}
        >
          <Conteudo>
            <Linha>
              <ImagemUsuario>
                {avatar && avatar.foto ? (
                  <img src={avatar.foto} />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
              </ImagemUsuario>
              <EditorUnform
                id="comentario"
                name="comentario"
                label="Adicionar um comentário"
              />
            </Linha>
          </Conteudo>
          <ContainerBotao>
            {visaoResposta ? (
              <InfoPrivado data-tip={dica2.dicaInfo} data-for={dica2.dicaId}>
                <Informacao />
                <Tooltip id={dica2.dicaId} place={'right'} html={true} />
              </InfoPrivado>
            ) : (
              <InfoPrivado data-tip={dica.dicaInfo} data-for={dica.dicaId}>
                <Informacao />
                <Tooltip id={dica.dicaId} place={'right'} html={true} />
              </InfoPrivado>
            )}
            <Botao
              texto={'Enviar Comentário'}
              carregando={carregando}
              type="button"
              onClick={() => formRef?.current?.submitForm()}
            />
          </ContainerBotao>
        </FormUnform>
      </ContainerResponder>
    ) : (
      <ContainerResponder
        onClick={() => aoClicarResponder(comentario.comentarioId)}
      >
        {IconeResponder}
        <TextoResponder> Responder </TextoResponder>
      </ContainerResponder>
    )
  }

  const renderizarComentario = (
    tipo: 'comentario' | 'resposta',
    comentario: ComentarioType,
    children?: JSX.Element
  ) => {
    return (
      <div key={comentario.comentarioId}>
        {tipo === 'comentario' ? IconeComentario : IconeRespostaComentario}
        <Conteudo>
          <TituloComentario>
            {comentario?.nomeUsuario}{' '}
            {comentario?.privado ? (
              <>
                <div
                  data-tip="Somente o criador do tópico/comentário e você poderão ver esse comentário"
                  data-for={`${comentario?.comentarioId}-tooltip-cadeado-comentario`}
                >
                  {IconeCadeado}
                </div>
                <Tooltip
                  id={`${comentario?.comentarioId}-tooltip-cadeado-comentario`}
                  place="right"
                  offset={{ top: 5 }}
                />
              </>
            ) : (
              <></>
            )}
          </TituloComentario>
          <ContainarStatus>
            <span>{tipo === 'comentario' ? 'Postado' : 'Respondido'} em</span>
            {IconeCalendarioPequeno}
            <span>{FuncoesDataHora.novaData(comentario?.dataInclusao)}</span>
            {IconeRelogioPequeno}
            <span>
              {FuncoesDataHora.horaFormatada24H(
                new Date(comentario?.dataInclusao)
              )}
            </span>
          </ContainarStatus>
          <TextoComentario>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeUtil(
                  comentario?.texto?.replaceAll('<p></p>', '<br />')
                )
              }}
            />
          </TextoComentario>
          {children}
          {tipo === 'comentario' &&
            (comentario.usuarioId === usuario.id || visaoResposta) &&
            renderizarResponder(comentario)}
        </Conteudo>
      </div>
    )
  }

  return (
    <Container>
      {comentarios.map(comentario =>
        renderizarComentario(
          'comentario',
          comentario,
          <ContainerChildren>
            {comentario.respostasComentario.map(resposta =>
              renderizarComentario('resposta', resposta)
            )}
          </ContainerChildren>
        )
      )}
    </Container>
  )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import {
  IconeSetaAvancar,
  Botao,
  FormUnform,
  IconeDoAvatarDoUsuario,
  FormRef,
  Carregando,
  ComentarioTopicoArquivoInput
} from 'src/componentes'
import { CHAVE_AVATAR_ALUNO } from 'src/componentes/barra-superior-layout/providers/barra-superior.provider'
import { EditorUnform } from 'src/componentes/unform/editor'
import { sanitizeUtil } from 'src/componentes/utils'
import { Api } from 'src/servicos'
import * as Storage from 'src/servicos/local-storage'
import { Topico as TopicoType } from 'src/tipos/'

import { ComentarioTopicoTCC } from './comentario'
import { schema } from './schema'
import {
  Container,
  ContainerBotao,
  Conteudo,
  ImagemUsuario,
  ListaComentarios,
  TituloTopico
} from './styles'
import { TopicoProps } from './tipos'

const avatar = Storage.obter(CHAVE_AVATAR_ALUNO) as { foto: string }

export const TopicoTCC: React.FC<TopicoProps> = ({
  idTopico,
  idGrupoDiscussao,
  orientacaoEmAndamento
}) => {
  const formRef = useRef<FormRef>(null)
  const [topico, definirTopico] = useState({} as TopicoType)
  const [carregandoTopico, definirCarregandoTopico] = useState(false)
  const [carregando, definirCarregando] = useState(false)

  const carregarTopico = useCallback(async () => {
    try {
      definirCarregandoTopico(true)
      const resposta = await Api.RequisitarTopicoPorId(idTopico)

      definirTopico(resposta)
    } catch {
      definirTopico(null)
    } finally {
      definirCarregandoTopico(false)
    }
  }, [idTopico])

  const acaoSucesso = async (payload: {
    comentario: string
    privado: boolean
    arquivo?: File | string
  }) => {
    try {
      definirCarregando(true)

      await Api.CriarComentario(
        idGrupoDiscussao,
        idTopico,
        sanitizeUtil(payload.comentario),
        true,
        payload.arquivo
      )

      formRef.current?.clearField('comentario')
      formRef.current?.clearField('arquivo')
      await carregarTopico()
    } finally {
      definirCarregando(false)
    }
  }

  useEffect(() => {
    carregarTopico()
  }, [carregarTopico])

  return (
    <>
      <Container>
        <TituloTopico>
          <h3>Fórum de dúvidas</h3>
          {IconeSetaAvancar}
          <span>{topico?.titulo}</span>
        </TituloTopico>
        {orientacaoEmAndamento && (
          <FormUnform ref={formRef} acaoSucesso={acaoSucesso} schema={schema}>
            <Conteudo>
              <ImagemUsuario>
                {avatar && avatar.foto ? (
                  <img width="40" height="40" src={avatar.foto} />
                ) : (
                  IconeDoAvatarDoUsuario
                )}
              </ImagemUsuario>
              <EditorUnform
                id="comentario"
                name="comentario"
                label="Adicionar um comentário"
              />
            </Conteudo>
            <ContainerBotao>
              <ComentarioTopicoArquivoInput id="arquivo" name="arquivo" />
              <Botao texto="Enviar Mensagem" carregando={carregando} />
            </ContainerBotao>
          </FormUnform>
        )}
      </Container>
      {carregandoTopico ? (
        <Carregando texto="Carregando comentários ..." />
      ) : (
        <ListaComentarios>
          {topico && topico?.comentarios && (
            <ComentarioTopicoTCC
              tipo="comentario"
              comentarios={topico?.comentarios}
              topico={idTopico}
              grupo={idGrupoDiscussao}
              orientacao={orientacaoEmAndamento}
              carregarTopico={carregarTopico}
            />
          )}
        </ListaComentarios>
      )}
    </>
  )
}

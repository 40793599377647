/* eslint-disable no-console */
import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styled'
import { ItemNotificacaoProps } from './tipos'

import { NotificacoesContainer } from '..'

export const ItemNotificacao: React.FC<ItemNotificacaoProps> = ({
  className,
  unread,
  title,
  date,
  description,
  redirecionamento
}) => {
  const { remover } = NotificacoesContainer.useContainer()

  const removerNotificacao = () => {
    if (title === 'Dúvida Respondida') {
      remover(title)
    }
  }

  return (
    <Container className={className} naoLido={unread}>
      {redirecionamento && (
        <>
          <Link to={redirecionamento} onClick={removerNotificacao}>
            <h4>{title}</h4>
            <time>{date}</time>
            <p>{description}</p>
          </Link>
        </>
      )}
    </Container>
  )
}
